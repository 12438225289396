



























































































































import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import { VueGoodTable } from "vue-good-table";
import ModalAddUser from "@/components/Modal/ModalAddUser.vue";
import ModalChangePassword from "@/components/Modal/ModalChangePassword.vue";
import UserService from "@/services/UserService";
import { IUserInfo, UserInfo, UserRole, UserStatus, USER_ROLE } from "@/models";

@Component({
  components: { VueGoodTable, ModalAddUser, ModalChangePassword }
})
export default class UserTable extends Vue {
  private isLoading: boolean = true;
  private selectedUser: UserInfo = new UserInfo();
  private loginedUser: IUserInfo = this.$store.state.user;

  private columns: any[] = [];
  private rows: UserInfo[] = [];
  private eUserRole = UserRole;
  private eUserStatus = UserStatus;

  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };

  private paginationOptions: any = {
    enabled: true,

    perPage: 10,
    position: "bottom",
    perPageDropdownEnabled: false,
    jumpFirstOrLast: false,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev"),
    firstLabel: "Đầu",
    lastLabel: "Cuối"
  };

  created() {
    this.setTableColumn();
    this.getListUser();
  }

  /**
   * Call API get list user
   */
  async getListUser() {
    UserService.getAll().then(res => {
      if (res.status === 200) {
        this.rows = res.data.map((item: any) => new UserInfo(item));
        this.isLoading = false;
      }
    });
  }

  /**
   * Config comlumn
   */
  setTableColumn() {
    this.columns = [
      {
        label: "#",
        field: "id",
        hidden: true
      },
      {
        label: "STT",
        field: "index",
        width: "50px",
        sortable: false
      },
      {
        label: "Tên",
        field: "name"
      },
      {
        label: "SĐT",
        field: "phone"
      },
      {
        label: "Tên đăng nhập",
        field: "username"
      },
      {
        label: "Vai trò",
        field: "role"
      },
      {
        label: "Trạng thái",
        field: "status"
      },
      {
        label: "GC",
        tooltip: "Ghi chú",
        field: "notice",
        width: "50px",
        sortable: false
      }
    ];
    if (this.loginedUser.role === UserRole.ADMIN) {
      this.columns.push({
        label: "",
        field: "action",
        width: "200px",
        sortable: false
      });
    }
  }

  hanldeSelectRow(row: UserInfo, isEdit: boolean) {
    if (this.loginedUser.role !== UserRole.ADMIN) return;
    this.selectedUser = row;
    if (isEdit) {
      setTimeout(() => {
        this.$bvModal.show("modal-update-user");
      }, 10);
    } else {
      if (this.selectedUser.username === this.loginedUser.username) return;
      this.$bvModal.show("modal-change-password-admin");
    }
  }

  submitUpdate(updateUser: UserInfo) {
    if (
      this.loginedUser.username === this.selectedUser.username &&
      this.selectedUser.username !== updateUser.username
    ) {
      localStorage.clear();
      this.$router.push({ name: "login" });
    }

    const currentUsser = this.rows[this.selectedUser.originalIndex];
    currentUsser.name = updateUser.name;
    currentUsser.role = updateUser.role;
    currentUsser.username = updateUser.username;
    currentUsser.status = updateUser.status;
    currentUsser.phone = updateUser.phone;
    currentUsser.notice = updateUser.notice;
  }

  getRoleName(userRole: USER_ROLE) {
    switch (userRole) {
      case USER_ROLE.ADMIN:
        return "ADMIN";
      case USER_ROLE.MODERATOR:
        return "MODERATOR";
      case USER_ROLE.GUEST:
        return "GUEST";
      case USER_ROLE.ACC:
        return "ACCOUNTANT";
      default:
        return "ACCOUNTANT";
    }
  }
}
