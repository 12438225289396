























































































import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import { VueGoodTable } from "vue-good-table";
import {
  CommonData,
  IUserInfo,
  ModalAddType,
  UserInfo,
  UserRole
} from "@/models";
import CommonService from "@/services/CommonService";

@Component({ components: { VueGoodTable } })
export default class CompanyTable extends Vue {
  private isLoading: boolean = true;
  private selectedCompany: CommonData = new CommonData();
  private loginedUser: IUserInfo = this.$store.state.user;
  private eModalAddType = ModalAddType;
  private isMax: boolean = true;

  private columns: any[] = [];
  private rows: CommonData[] = [];
  private eUserRole = UserRole;

  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };

  private paginationOptions: any = {
    enabled: true,

    perPage: 10,
    position: "bottom",
    perPageDropdownEnabled: false,
    jumpFirstOrLast: false,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev"),
    firstLabel: "Đầu",
    lastLabel: "Cuối"
  };

  created() {
    this.setTableColumn();
    this.getListCompany();
  }

  /**
   * Call API get list user
   */
  async getListCompany() {
    CommonService.getAllCompany().then(res => {
      if (res.status === 200) {
        this.rows = res.data.map((item: any) => new CommonData(item));
        this.isMax = this.rows.length === 10;
        this.isLoading = false;
      }
    });
  }

  /**
   * Config comlumn
   */
  setTableColumn() {
    this.columns = [
      {
        label: "#",
        field: "id",
        hidden: true
      },
      {
        label: "STT",
        field: "index",
        width: "50px",
        sortable: false
      },
      {
        label: "Mã",
        field: "code"
      },
      {
        label: "Tên",
        field: "name"
      },
      {
        label: "Màu",
        field: "color"
      },
      {
        label: "GC",
        tooltip: "Ghi chú",
        field: "notice",
        width: "50px",
        sortable: false
      }
    ];
    if (this.loginedUser.role === UserRole.ADMIN) {
      this.columns.push({
        label: "",
        field: "action",
        width: "90px",
        sortable: false
      });
    }
  }

  hanldeSelectRow(row: CommonData) {
    if (this.loginedUser.role !== UserRole.ADMIN) return;
    this.selectedCompany = row;
    setTimeout(() => this.$bvModal.show("modal-edit-company"), 10);
  }
}
