





































































































































































































































































































































































import { UserInfo, UserRole, UserStatus, USER_ROLE } from "@/models";
import UserService from "@/services/UserService";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalAddUser extends Vue {
  @Prop({ default: "modal-add-user" }) private id!: string;
  @Prop() private selectedUser!: UserInfo;
  @Prop() private listUsername!: string[];
  @Prop() private isCreate!: boolean;
  private data: UserInfo = new UserInfo();
  private password: string = "";
  private isEdit: boolean = false;
  private USER_ROLE = USER_ROLE;
  private UserStatus = UserStatus;

  handleModalShow() {
    if (this.selectedUser && this.selectedUser.id) {
      this.data = this.$lodash.cloneDeep(this.selectedUser);
      this.isEdit = true;
    } else {
      this.data = new UserInfo();
      this.isEdit = false;
    }
  }

  submitAdd() {
    const newUser = this.data;
    UserService.updateUser(newUser)
      .then(res => {
        if (res.status === 200) {
          if (this.isCreate) this.changePassword();
          this.$emit("submit", this.data);
          this.$swal({
            title: !this.isCreate
              ? "Cập nhât thành công"
              : "Thêm mới thành công",
            icon: "success",
            timer: 2000,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end"
          });
          this.$bvModal.hide(this.id);
        }
      })
      .catch(() => {
        this.$swal({
          title: !this.isCreate ? "Cập nhât thất bại" : "Thêm mới thất bại",
          icon: "error",
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          position: "bottom-end"
        });
      });
  }

  changePassword() {
    const md5 = require("md5");
    UserService.changePasswordAdmin(
      this.data.username,
      md5(this.password.trim())
    );
  }
}
