












import { Component, Vue } from "vue-property-decorator";
import UserTable from "@/views/Config/UserTable.vue";
import CompanyTable from "@/views/Config/CompanyTable.vue";
import OriginTable from "@/views/Config/OriginTable.vue";
import SupplierTable from "@/views/Config/SupplierTable.vue";
import ProductTable from "@/views/Config/ProductTable.vue";
import PortTable from "@/views/Config/PortTable.vue";
import CarrierTable from "@/views/Config/CarrierTable.vue";

@Component({
  components: {
    UserTable,
    CompanyTable,
    OriginTable,
    SupplierTable,
    ProductTable,
    PortTable,
    CarrierTable
  }
})
export default class Config extends Vue {}
